<template>
  <v-container>
    <SectionTitle>Services</SectionTitle>

    <h4>I help businesses figure out what software they need, then I build that software.</h4>
    <v-card
      class="mt-10 pa-10 d-flex flex-wrap elevation-0"
      style="border: 1px solid white; background-color: transparent"
    >
      <h4 v-for="item in ['SaaS', 'B2B', 'B2C', 'For internal use', 'In the cloud']" :key="item" class="mr-10">
        <v-icon size="45" color="secondary" class="mb-2">mdi-check</v-icon> {{ item }}
      </h4>
    </v-card>

    <h4 class="mt-24">The process often looks like this.</h4>

    <Plan class="mt-6" />

    <h4 class="mt-12">
      The result is <span class="font-weight-medium">performant</span> and
      <span class="font-weight-medium">reliable</span> web applications that
      <span class="font-weight-medium">work</span>.
    </h4>
  </v-container>
</template>

<script>
import SectionTitle from '@/components/SectionTitle'
import Plan from '@/components/Plan'

export default {
  components: {
    SectionTitle,
    Plan,
  },
}
</script>
