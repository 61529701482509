import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import { VueMasonryPlugin } from 'vue-masonry'

Vue.use(VueMasonryPlugin)
Vue.config.productionTip = false

const vm = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')

Vue.prototype.$goTo = (selector, offset = 60) => vm.$vuetify.goTo(selector, { offset })
