<template>
  <v-card class="pa-10 pt-8">
    <v-row align="end">
      <!-- Techs -->
      <v-col cols="12" lg="auto" class="d-flex align-end">
        <img class="mr-4" height="60" :src="logo" />
        <h3>{{ tech }}</h3>
      </v-col>

      <!-- Addon tech -->
      <v-col cols="12" lg="auto" class="d-flex align-end">
        <h4 class="mr-3" v-if="addonLogo || addonTech">+</h4>
        <img class="mr-3" v-if="addonLogo" height="40" :src="addonLogo" />
        <h4 v-if="addonTech">{{ addonTech }}</h4>
      </v-col>

      <!-- Stream -->
      <v-col class="text-lg-right">
        <span>{{ stream }}</span>
      </v-col>

      <!-- Rank -->
      <v-col cols="12" lg="auto">
        <span class="rank font-weight-bold secondary--text text-uppercase">{{ rank }}</span>
      </v-col>
    </v-row>

    <!-- Progress -->
    <v-progress-linear :value="level" class="mt-8" color="primary" height="15" striped />
  </v-card>
</template>

<script>
export default {
  props: {
    tech: String,
    logo: String,
    addonTech: String,
    addonLogo: String,
    stream: String,
    rank: String,
    level: Number,
  },
}
</script>

<style scoped>
.rank {
  letter-spacing: 1px;
}
</style>
