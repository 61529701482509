<template>
  <v-container>
    <SectionTitle>Contact</SectionTitle>

    <!-- Email -->
    <v-row class="mt-12">
      <v-col cols="12" sm="2">
        <h4>Email me</h4>
      </v-col>
      <v-col cols="12" sm="">
        <v-btn color="primary" x-large width="250" href="mailto:software@danlynkew.com">
          <v-icon left>mdi-email</v-icon> software@danlynkew.com
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SectionTitle from '@/components/SectionTitle'

export default {
  components: {
    SectionTitle,
  },
}
</script>
