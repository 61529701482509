<template>
  <div class="pa-1" :style="{ marginLeft, height }">
    <v-card class="pl-3 d-flex align-center" style="cursor: default" :color="color" :width="width" height="100%">
      {{ title }}
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    color: String,
    tooltip: String,
    offset: Number,
    length: Number,

    scaleX: Number,
    scaleY: Number,
  },
  computed: {
    width() {
      return this.length * this.scaleX
    },
    height() {
      return `${this.scaleY}px`
    },
    marginLeft() {
      return `${this.offset * this.scaleX}px`
    },
  },
}
</script>
