<template>
  <v-card>
    <slot name="media" />
    <v-card-title>{{ title }}</v-card-title>
    <v-card-text>
      <slot name="description" />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    title: String,
  },
}
</script>
