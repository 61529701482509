<template>
  <div class="video-container">
    <iframe :src="`//youtube.com/embed/${slug}`" class="video" frameborder="0" allowfullscreen />
  </div>
</template>

<script>
export default {
  props: {
    slug: String,
  },
}
</script>

<style scoped>
.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
</style>
