<template>
  <v-container>
    <SectionTitle>Hobbies</SectionTitle>

    <v-row v-masonry>
      <v-col cols="12" sm="6" md="4" lg="3">
        <HobbyCard title="Gymnastics">
          <template v-slot:media>
            <v-img height="250" src="@/assets/hobby-gymnastics.png" />
          </template>
          <template v-slot:description>
            I was a competitive gymnast throughout my youth. I placed 4th at Eastern Canadian Championships in 2008. I
            went on to become a nationally-certified gymnastics coach, then a coach educator for Canada, then a
            consultant to gymnastics club owners.
          </template>
        </HobbyCard>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <HobbyCard title="Taekwondo">
          <template v-slot:media>
            <v-img height="250" src="@/assets/hobby-taekwondo.png" />
          </template>
          <template v-slot:description>
            I trained under Master Ekkehard Zorn for 10 years, earning my first-degree black belt and becoming an
            assistant instructor.
          </template>
        </HobbyCard>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <HobbyCard title="Travel">
          <template v-slot:media>
            <v-img height="250" src="@/assets/hobby-travel.jpg" />
          </template>
          <template v-slot:description>
            I've toured most countries in Europe. When the pandemic ends, I'll complete Europe and move on to Asia. I've
            also toured Iceland's Golden Circle and bathed in its famous Blue Lagoon.
          </template>
        </HobbyCard>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <HobbyCard title="3D Graphics">
          <template v-slot:media>
            <Cube size="210" background-color="#1e1e1e" />
          </template>
          <template v-slot:description>
            I was sitting in my linear algebra course learning about transformation matrices when I realized all at once
            that I knew how to program 3D graphics. One day I'll build games as a hobby.
          </template>
        </HobbyCard>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <HobbyCard title="Powerlifting">
          <template v-slot:media>
            <v-img height="250" src="@/assets/hobby-powerlifting.jpg" />
          </template>
          <template v-slot:description>
            <div>Max Squat: <strong>225 lbs</strong></div>
            <div class="mt-2">Max Bench: <strong>200 lbs</strong></div>
            <div class="mt-2">Max Deadlift: <strong>200 lbs</strong></div>
          </template>
        </HobbyCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SectionTitle from '@/components/SectionTitle'
import HobbyCard from '@/components/HobbyCard'
import Cube from '@/components/Cube'

export default {
  components: {
    SectionTitle,
    HobbyCard,
    Cube,
  },
}
</script>
