<template>
  <section class="d-flex" style="overflow-x: scroll">
    <v-card
      v-for="phase in phases"
      :key="phase.title"
      class="pa-6 mr-6"
      :color="$vuetify.theme.themes.dark.button"
      style="max-width: none"
    >
      <h4 class="mb-6">{{ phase.title }}</h4>
      <Timeline :tasks="phase.tasks" :scale-x="70" :scale-y="60" />
    </v-card>
  </section>
</template>

<script>
import Timeline from '@/components/Timeline.vue'

export default {
  components: {
    Timeline,
  },
  data() {
    return {
      phases: [
        {
          title: 'Consulting',
          tasks: [
            {
              title: 'Discovery & Onboarding',
              tooltip:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
              offset: 0,
              length: 3,
            },
            {
              title: 'Gap Analysis',
              tooltip:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
              offset: 1,
              length: 3,
            },
            {
              title: 'Project Planning',
              tooltip:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
              offset: 2,
              length: 3,
            },
            {
              title: 'ROI Modelling',
              tooltip:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
              offset: 3,
              length: 3,
            },
          ],
        },
        {
          title: 'Development',
          tasks: [
            {
              title: 'CI/CD',
              tooltip:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
              offset: 0,
              length: 2,
            },
            {
              title: 'Backend Development',
              tooltip:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
              offset: 2,
              length: 5,
            },
            {
              title: 'Frontend Development',
              tooltip:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
              offset: 7,
              length: 10,
            },
            {
              title: 'End-to-End Testing',
              tooltip:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
              offset: 2,
              length: 15,
            },
            {
              title: 'Analytics',
              tooltip:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
              offset: 15,
              length: 2,
            },
          ],
        },
        {
          title: 'Launch',
          tasks: [
            {
              title: 'Release',
              tooltip:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
              offset: 0,
              length: 2,
            },
            {
              title: 'Ongoing Bug Tracking & Fixing',
              tooltip:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
              offset: 1,
              length: 20,
            },
            {
              title: 'Continuous Feedback & Feature Development',
              tooltip:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
              offset: 1,
              length: 20,
            },
          ],
        },
      ],
    }
  },
}
</script>
