<template>
  <v-container>
    <SectionTitle>Portfolio</SectionTitle>

    <v-expansion-panels>
      <!-- Essay App -->
      <PortfolioItem
        project="Essay App"
        client="Educational product for Dr. Jordan B. Peterson"
        :techs="[
          {
            name: 'React',
            logo: require('@/assets/logo-react.png'),
          },
          {
            name: 'Django',
            logo: require('@/assets/logo-django.svg'),
          },
          {
            name: 'GitHub',
            logo: require('@/assets/logo-github.png'),
          },
          {
            name: 'Apache',
            logo: require('@/assets/logo-apache.png'),
          },
        ]"
        testimonial="Dan has been instrumental in the design, development and strategic progress of Essay App, and our numerous other projects over the last 3 years. He has a rare combination of creative talent and technical ability. Dan is a pleasure to work with. He is a self-motivated learner, and stays on top of the latest tech to solve problems more efficiently and effectively."
        :avatar="require('@/assets/avatar-julian.jpg')"
        author="Julian Peterson"
        occupation="Tech Lead &amp; Project Manager"
      >
        <template v-slot:description>
          <div>
            This is an educational project I'm working on for Dr. Jordan B. Peterson. Any Peterson fan knows how highly
            he values clarity of thought, speech, and writing. One of his endeavours right now is to teach the world to
            organize their thoughts through writing. His son Julian and I are working together to develop and release
            this product which teaches individuals how to reliably produce high-quality writing. The platform presents
            Dr. Peterson's popular Essay Writing Guide and provides specialized tools that make following the guide easy
            and natural.
          </div>
          <Checklist
            :items="[
              'Social authentication',
              'Integrated text editor',
              'Advanced text manipulation tools',
              'Product demos on landing page',
              'Payment integration & free trials',
              'Dashboard where users can manage their subscription',
            ]"
          />
        </template>
      </PortfolioItem>

      <!-- Audiospritz -->
      <PortfolioItem
        project="Audio-Visual Experiment Platform"
        client="Rapid prototype for University of Toronto"
        :techs="[
          {
            name: 'Vue',
            logo: require('@/assets/logo-vue.svg'),
          },
          {
            name: 'Django',
            logo: require('@/assets/logo-django.svg'),
          },
          {
            name: 'GitHub',
            logo: require('@/assets/logo-github.png'),
          },
          {
            name: 'Apache',
            logo: require('@/assets/logo-apache.png'),
          },
        ]"
        testimonial="Dan helped me design a web app for conducting a psychology experiment. He built out the proof-of-concept in under a week which helped me move quickly into the next phases of my research."
        :avatar="require('@/assets/avatar-john.jpg')"
        author="John Tennant"
        occupation="Psychology Researcher, University of Toronto"
      >
        <template v-slot:media>
          <EmbeddedVideo slug="98vYRmJjKDE" />
        </template>
        <template v-slot:description>
          <div>
            Psychology researchers at the University of Toronto wanted to determine whether supplementing audio with
            synced visuals enhances our ability to learn and retain information. They onboarded me with their
            experimental conditions and objectives, then I designed and built this platform for them. The platform
            administers the experimental condition, quizzes participants to determine their information retention, then
            reports the results back to the researchers.
          </div>
          <Checklist
            :items="[
              'Collects consent forms electronically from participants',
              'Automatically assigns users to experimental condition',
              'Collects experimental data with millisecond accuracy',
              'Administers multiple-choice quizzes to participants',
              'Scores participants & reports results',
              'Screens out repeat visitors',
              'Integrates with MTurk',
            ]"
          />
        </template>
      </PortfolioItem>

      <!-- Remote Funerals -->
      <PortfolioItem
        project="Remote Funerals Platform"
        client="Rapid prototype for myself and partners"
        :techs="[
          {
            name: 'Vue',
            logo: require('@/assets/logo-vue.svg'),
          },
          {
            name: 'Django',
            logo: require('@/assets/logo-django.svg'),
          },
          {
            name: 'GitHub',
            logo: require('@/assets/logo-github.png'),
          },
          {
            name: 'Apache',
            logo: require('@/assets/logo-apache.png'),
          },
        ]"
      >
        <template v-slot:media>
          <EmbeddedVideo slug="qidyb65Lb4E" />
        </template>
        <template v-slot:description>
          <div>
            At the beginning of the pandemic, I, my fiancée, and two of my friends had the idea of offering livestreamed
            funeral services to families who weren't allowed to physically congregate. Full of enthusiasm, we raced to
            rapidly prototype a platform to see whether the idea was technically viable. It was.
          </div>
          <Checklist
            :items="[
              'Email authentication',
              'Remotely-controlled livestreaming',
              'User commenting',
              'Payment integration',
              'Dashboard where users can edit their own page',
            ]"
          />
          <div>
            Although we decided not to take the idea any further, my big boast here is that I designed and built the
            platform in under 72 hours.
          </div>
        </template>
      </PortfolioItem>

      <!-- Video Contest -->
      <PortfolioItem
        project="Educational Video Contest Platform"
        client="Rapid prototype for Dr. Jordan B Peterson"
        :techs="[
          {
            name: 'Vue',
            logo: require('@/assets/logo-vue.svg'),
          },
          {
            name: 'Django',
            logo: require('@/assets/logo-django.svg'),
          },
          {
            name: 'GitHub',
            logo: require('@/assets/logo-github.png'),
          },
          {
            name: 'AWS',
            logo: require('@/assets/logo-aws.png'),
          },
        ]"
        :testimonial="null"
        :avatar="require('@/assets/avatar-jordan.jpg')"
        author="Dr. Jordan B. Peterson"
        occupation="Clinical Psychologist &amp; Best-Selling Author"
      >
        <template v-slot:description>
          <div>
            I've been on the core team of Dr. Peterson's educational thinktank since 2018. One of our ideas was an
            online platform that would facilitate contests between educational content creators. We rapidly prototyped
            this platform which enables creators to submit their educational videos to month-long contests. Viewers can
            then log in and vote for the best content, and a winner is declared.
          </div>
          <Checklist
            :items="[
              'Social authentication',
              'Embedded video content',
              'Round-robin contest administration',
              'User voting & commenting',
            ]"
          />
        </template>
      </PortfolioItem>

      <!-- Whitby Gym & Circus -->
      <PortfolioItem
        project="Marketing Website &amp; Pre-Registration Platform"
        client="for Whitby Gym &amp; Circus"
        :techs="[
          {
            name: 'Vue',
            logo: require('@/assets/logo-vue.svg'),
          },
          {
            name: 'Django',
            logo: require('@/assets/logo-django.svg'),
          },
          {
            name: 'GitHub',
            logo: require('@/assets/logo-github.png'),
          },
          {
            name: 'Apache',
            logo: require('@/assets/logo-apache.png'),
          },
        ]"
        testimonial="Dan is critical to my operations. He gets stuff done the way I would do it myself. Will be working with this guy for life."
        :avatar="require('@/assets/avatar-kim.png')"
        author="Kim Dunning"
        occupation="Gymnastics Club Owner"
      >
        <template v-slot:media>
          <EmbeddedVideo slug="8ma3bgxijoM" />
        </template>
        <template v-slot:description>
          <div>
            Kim and I started working together because she wasn't happy with the website she had put together herself on
            Wix. She didn't like her design, and she couldn't display her gymnastics class offerings the way she wanted
            to. I hired a professional designer, built Kim her pixel-perfect new website, and added all the
            functionality her business needed from it.
          </div>
          <Checklist
            :items="[
              'Shows class availability in real-time',
              'Allows registrants to reserve class times',
              'Collects signed waivers electronically from registrants',
              'Encourages visitors to sign up for the mailing list',
              'Google Analytics & Google Ads integration',
            ]"
          />
        </template>
      </PortfolioItem>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import SectionTitle from '@/components/SectionTitle'
import PortfolioItem from '@/components/PortfolioItem'
import EmbeddedVideo from '@/components/EmbeddedVideo'
import Checklist from '@/components/Checklist'

export default {
  components: {
    SectionTitle,
    PortfolioItem,
    EmbeddedVideo,
    Checklist,
  },
}
</script>
