import Home from '@/views/Home.vue'
import PageNotFound from '@/views/PageNotFound.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'Dan Lyn Kew | Full-Stack Developer & Business Technology Consultant',
    },
  },
  {
    path: '*',
    name: '404',
    component: PageNotFound,
    meta: {
      title: 'Dan Lyn Kew | Page Not Found',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

export default router
