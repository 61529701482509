<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <template v-slot:default="{ open }">
        <v-row align="center">
          <v-col cols="12" md="6">
            <h5>{{ project }}</h5>
            <div class="mt-1 text--disabled">{{ client }}</div>
            <div class="mt-2 d-flex align-center" style="height: 20px">
              <v-slide-y-reverse-transition>
                <v-rating v-if="!open || !testimonial" :value="5" size="20" color="amber" dense readonly></v-rating>
              </v-slide-y-reverse-transition>
            </div>
          </v-col>
          <v-col v-for="tech in techs" :key="tech.name" cols="auto" class="d-flex align-center">
            <img :src="tech.logo" height="25" class="mr-2" />
            <span class="text--disabled">{{ tech.name }}</span>
          </v-col>
        </v-row>
      </template>
    </v-expansion-panel-header>

    <v-expansion-panel-content class="pb-3">
      <v-row>
        <!-- Video -->
        <v-col v-if="$slots.media" cols="12" md="5">
          <slot name="media" />
        </v-col>

        <!-- Description -->
        <v-col cols="12" :md="$slots.media ? 7 : null">
          <slot name="description" />
        </v-col>

        <!-- Testimonial -->
        <v-col cols="12">
          <v-card v-if="testimonial" color="#131313" class="pa-4 d-flex align-center">
            <div>
              <v-avatar size="60">
                <img :src="avatar" />
              </v-avatar>
            </div>
            <div class="ml-4">
              <div>&ldquo;{{ testimonial }}&rdquo;</div>
              <div class="text--secondary">
                <span v-if="author">{{ author }}</span>
                <span v-if="author && occupation"> | </span>
                <span v-if="occupation">{{ occupation }}</span>
              </div>
              <v-rating :value="5" size="20" color="amber" dense readonly></v-rating>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: {
    project: String,
    client: String,
    techs: Array,
    testimonial: String,
    avatar: String,
    author: String,
    occupation: String,
  },
}
</script>
