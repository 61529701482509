<template>
  <div class="text-center">
    <canvas id="webgl-canvas-cube" :width="size" :height="size" />
  </div>
</template>

<script>
export default {
  props: {
    size: String,
    backgroundColor: String,
  },
  data() {
    return {
      r: 0,
      g: 0,
      b: 0,
    }
  },
  mounted() {
    const rgb = this.hexToRgb(this.backgroundColor.replace('#', ''))
    this.r = rgb.r
    this.g = rgb.g
    this.b = rgb.b
    this.initializeWebGL()
  },
  methods: {
    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    },
    initializeWebGL() {
      // Initialize WebGL
      var canvas = document.getElementById('webgl-canvas-cube')
      var gl = canvas.getContext('webgl')
      if (!gl) {
        console.error('Browser does not support webgl context. Falling back on experimental-webgl context.')
        gl = canvas.getContext('experimental-webgl')
      }
      if (!gl) {
        alert('Your browser does not support WebGL. Please update your browser.')
        return
        // exit()
      }

      // Specify some WebGL settings
      gl.enable(gl.DEPTH_TEST) // Don't draw pixels that are behind other pixels
      gl.enable(gl.CULL_FACE) // Don't even calculate pixels that are behind other pixels
      gl.frontFace(gl.CCW)
      gl.cullFace(gl.BACK)

      // Compile vertex shader
      var vertexShaderText = [
        'precision mediump float;',
        'attribute vec3 vertexPos;', // input
        'attribute vec3 vertexColor;', // input
        'varying vec3 fragmentColor;', // output
        'uniform mat4 mWorld;', // output
        'uniform mat4 mView;', // output
        'uniform mat4 mProj;', // output
        'void main () {',
        '   gl_Position = mProj * mView * mWorld * vec4(vertexPos, 1.0);',
        '   fragmentColor = vertexColor;',
        '}',
      ].join('\n')
      var vertexShader = gl.createShader(gl.VERTEX_SHADER)
      gl.shaderSource(vertexShader, vertexShaderText)
      gl.compileShader(vertexShader)
      if (!gl.getShaderParameter(vertexShader, gl.COMPILE_STATUS)) {
        console.error('Error compiling vertex shader: ', gl.getShaderInfoLog(vertexShader))
        return
        // exit()
      }

      // Compile fragment shader
      var fragmentShaderText = [
        'precision mediump float;',
        'varying vec3 fragmentColor;', // input
        'void main () {',
        '   gl_FragColor = vec4(fragmentColor, 1.0);',
        '}',
      ].join('\n')
      var fragmentShader = gl.createShader(gl.FRAGMENT_SHADER)
      gl.shaderSource(fragmentShader, fragmentShaderText)
      gl.compileShader(fragmentShader)
      if (!gl.getShaderParameter(fragmentShader, gl.COMPILE_STATUS)) {
        console.error('Error compiling fragment shader: ', gl.getShaderInfoLog(fragmentShader))
        return
        // exit()
      }

      // Create, link, and validate WebGL program
      var program = gl.createProgram()
      gl.attachShader(program, vertexShader)
      gl.attachShader(program, fragmentShader)
      gl.linkProgram(program)
      if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
        console.error('Error linking program: ', gl.getProgramInfoLog(program))
        return
        // exit()
      }
      gl.validateProgram(program)
      if (!gl.getProgramParameter(program, gl.VALIDATE_STATUS)) {
        console.error('Error validating program: ', gl.getProgramInfoLog(program))
        return
        // exit()
      }

      // Specify box vertices
      var boxVertices = [
        // x, y, z,         R, G, B

        // Top
        -1.0,
        1.0,
        -1.0,
        255 / 255,
        102 / 255,
        102 / 255,
        -1.0,
        1.0,
        1.0,
        255 / 255,
        102 / 255,
        102 / 255,
        1.0,
        1.0,
        1.0,
        255 / 255,
        102 / 255,
        102 / 255,
        1.0,
        1.0,
        -1.0,
        255 / 255,
        102 / 255,
        102 / 255,

        // Left
        -1.0,
        1.0,
        1.0,
        255 / 255,
        255 / 255,
        102 / 255,
        -1.0,
        -1.0,
        1.0,
        255 / 255,
        255 / 255,
        102 / 255,
        -1.0,
        -1.0,
        -1.0,
        255 / 255,
        255 / 255,
        102 / 255,
        -1.0,
        1.0,
        -1.0,
        255 / 255,
        255 / 255,
        102 / 255,

        // Right
        1.0,
        1.0,
        1.0,
        102 / 255,
        255 / 255,
        140 / 255,
        1.0,
        -1.0,
        1.0,
        102 / 255,
        255 / 255,
        140 / 255,
        1.0,
        -1.0,
        -1.0,
        102 / 255,
        255 / 255,
        140 / 255,
        1.0,
        1.0,
        -1.0,
        102 / 255,
        255 / 255,
        140 / 255,

        // Front
        1.0,
        1.0,
        1.0,
        102 / 255,
        179 / 255,
        255 / 255,
        1.0,
        -1.0,
        1.0,
        102 / 255,
        179 / 255,
        255 / 255,
        -1.0,
        -1.0,
        1.0,
        102 / 255,
        179 / 255,
        255 / 255,
        -1.0,
        1.0,
        1.0,
        102 / 255,
        179 / 255,
        255 / 255,

        // Back
        1.0,
        1.0,
        -1.0,
        179 / 255,
        102 / 255,
        255 / 255,
        1.0,
        -1.0,
        -1.0,
        179 / 255,
        102 / 255,
        255 / 255,
        -1.0,
        -1.0,
        -1.0,
        179 / 255,
        102 / 255,
        255 / 255,
        -1.0,
        1.0,
        -1.0,
        179 / 255,
        102 / 255,
        255 / 255,

        // Bottom
        -1.0,
        -1.0,
        -1.0,
        255 / 255,
        153 / 255,
        0 / 255,
        -1.0,
        -1.0,
        1.0,
        255 / 255,
        153 / 255,
        0 / 255,
        1.0,
        -1.0,
        1.0,
        255 / 255,
        153 / 255,
        0 / 255,
        1.0,
        -1.0,
        -1.0,
        255 / 255,
        153 / 255,
        0 / 255,
      ]

      // Specify box vertex indices
      var boxIndices = [
        // Top
        0,
        1,
        2,
        0,
        2,
        3,

        // Left
        5,
        4,
        6,
        6,
        4,
        7,

        // Right
        8,
        9,
        10,
        8,
        10,
        11,

        // Front
        13,
        12,
        14,
        15,
        14,
        12,

        // Back
        16,
        17,
        18,
        16,
        18,
        19,

        // Bottom
        21,
        20,
        22,
        22,
        20,
        23,
      ]

      // Send vertex data to GPU via a buffer
      var boxVertexBuffer = gl.createBuffer()
      gl.bindBuffer(gl.ARRAY_BUFFER, boxVertexBuffer)
      gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(boxVertices), gl.STATIC_DRAW)

      var boxIndexBuffer = gl.createBuffer()
      gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, boxIndexBuffer)
      gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(boxIndices), gl.STATIC_DRAW)

      // Point vertex shader to vertex positions
      var positionAttribLocation = gl.getAttribLocation(program, 'vertexPos')
      gl.vertexAttribPointer(
        positionAttribLocation, // Attribute location
        3, // Number of elements per attribute
        gl.FLOAT, // Type of elements
        gl.FALSE,
        6 * Float32Array.BYTES_PER_ELEMENT, // Size of each vertex
        0 // Position offset from beginning of single vertex to this attribute
      )
      gl.enableVertexAttribArray(positionAttribLocation)

      // Point vertex shader to vertex colours
      var colorAttribLocation = gl.getAttribLocation(program, 'vertexColor')
      gl.vertexAttribPointer(
        colorAttribLocation, // Attribute location
        3, // Number of elements per attribute
        gl.FLOAT, // Type of elements
        gl.FALSE,
        6 * Float32Array.BYTES_PER_ELEMENT, // Size of each vertex
        3 * Float32Array.BYTES_PER_ELEMENT // Position offset from beginning of single vertex to this attribute
      )
      gl.enableVertexAttribArray(colorAttribLocation)

      // Tell WebGL state machine which program is active
      gl.useProgram(program)

      // Define world matrix
      var mWorldUniformLocation = gl.getUniformLocation(program, 'mWorld')
      var worldMatrix = new Float32Array(16)
      mat4.identity(worldMatrix) // eslint-disable-line no-undef
      gl.uniformMatrix4fv(mWorldUniformLocation, gl.FALSE, worldMatrix)

      // Define view matrix
      var mViewUniformLocation = gl.getUniformLocation(program, 'mView')
      var viewMatrix = new Float32Array(16)
      // mat4.lookAt(change this matrix, position of viewer, point viewer is looking at, which direction is up)
      mat4.lookAt(viewMatrix, [0, 0, -8], [0, 0, 0], [0, 1, 0]) // eslint-disable-line no-undef
      gl.uniformMatrix4fv(mViewUniformLocation, gl.FALSE, viewMatrix)

      // Define projection matrix
      var mProjUniformLocation = gl.getUniformLocation(program, 'mProj')
      var projMatrix = new Float32Array(16)
      // mat4.perspective(change this matrix, vertical field of view in radians, aspect ratio, near bound, far bound)
      mat4.perspective(projMatrix, glMatrix.toRadian(25), canvas.width / canvas.height, 0.1, 1000.0) // eslint-disable-line no-undef
      gl.uniformMatrix4fv(mProjUniformLocation, gl.FALSE, projMatrix)

      // Main render loop

      var rotationsPerSecond = 1 / 8
      var radiansPerRotation = 2 * Math.PI

      var secondsElapsed = 0
      var angle = 0

      var xRotMat = new Float32Array(16) // x-rotation matrix
      var yRotMat = new Float32Array(16) // y-rotation matrix

      var identityMatrix = new Float32Array(16)
      mat4.identity(identityMatrix) // eslint-disable-line no-undef

      var loop = () => {
        secondsElapsed = performance.now() / 1000 // performance.now() returns milliseconds elapsed since WebGL context was initiated
        angle = rotationsPerSecond * secondsElapsed * radiansPerRotation // rotations/second * seconds * radians/rotation = radians :)
        mat4.rotate(xRotMat, identityMatrix, angle, [1, 0, 0]) // eslint-disable-line no-undef
        mat4.rotate(yRotMat, identityMatrix, angle * 0.75, [0, 1, 0]) // eslint-disable-line no-undef
        mat4.mul(worldMatrix, yRotMat, xRotMat) // eslint-disable-line no-undef
        gl.uniformMatrix4fv(mWorldUniformLocation, gl.FALSE, worldMatrix)
        const r = this.r / 255
        const g = this.g / 255
        const b = this.b / 255
        gl.clearColor(r, g, b, 1.0)
        gl.clear(gl.DEPTH_BUFFER_BIT | gl.COLOR_BUFFER_BIT)
        gl.drawElements(gl.TRIANGLES, boxIndices.length, gl.UNSIGNED_SHORT, 0)

        requestAnimationFrame(loop)
      }
      requestAnimationFrame(loop)
    },
  },
}
</script>
