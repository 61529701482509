<template>
  <div>
    <Task
      v-for="(task, i) in tasks"
      :key="JSON.stringify(task)"
      v-bind="task"
      :color="getColor(i)"
      :scale-x="scaleX"
      :scale-y="scaleY"
    />
  </div>
</template>

<script>
import Task from '@/components/Task.vue'

export default {
  components: {
    Task,
  },
  props: {
    tasks: Array,
    scaleX: Number,
    scaleY: Number,
  },
  data() {
    return {
      colors: ['cyan darken-3', 'light-blue darken-3', 'indigo darken-3', 'deep-purple darken-3', 'purple darken-3'],
    }
  },
  methods: {
    getColor(index) {
      return this.colors[index % this.colors.length]
    },
  },
}
</script>
