<template>
  <table class="my-2">
    <tr v-for="item in items" :key="item">
      <td class="pt-1 pr-2">
        <v-icon :color="color">mdi-check</v-icon>
      </td>
      <td>{{ item }}</td>
    </tr>
  </table>
</template>

<script>
export default {
  props: {
    items: Array,
    color: { type: String, default: 'secondary' },
  },
}
</script>
