<template>
  <main>
    <!-- Nav drawer -->
    <v-navigation-drawer v-show="$vuetify.breakpoint.smAndUp" app width="150">
      <v-list style="margin-top: 30vh" dense>
        <a
          v-for="section in sections"
          :key="section.id"
          :class="{ active: activeSectionId === section.id }"
          @click="scrollToSection(section.id)"
        >
          {{ section.title }}
        </a>
      </v-list>
    </v-navigation-drawer>

    <!-- Content -->
    <v-main>
      <Splash />
      <v-divider />
      <Services data-scrollspy="services" />
      <v-divider />
      <Skills data-scrollspy="skills" />
      <v-divider />
      <Portfolio data-scrollspy="portfolio" />
      <v-divider />
      <Hobbies data-scrollspy="hobbies" />
      <v-divider />
      <Contact data-scrollspy="contact" />
      <v-divider class="mb-0" />
      <Footer />
    </v-main>
  </main>
</template>

<script>
import Splash from '@/components/Splash'
import Services from '@/components/Services'
import Skills from '@/components/Skills'
import Portfolio from '@/components/Portfolio'
import Hobbies from '@/components/Hobbies'
import Contact from '@/components/Contact'
import Footer from '@/components/Footer'

export default {
  components: {
    Splash,
    Services,
    Skills,
    Portfolio,
    Hobbies,
    Contact,
    Footer,
  },
  data() {
    return {
      drawer: true,
      sections: [
        {
          id: 'services',
          title: 'Services',
        },
        {
          id: 'skills',
          title: 'Skills',
        },
        {
          id: 'portfolio',
          title: 'Portfolio',
        },
        {
          id: 'hobbies',
          title: 'Hobbies 🔥',
        },
        {
          id: 'contact',
          title: 'Contact',
        },
      ],
      activeSectionId: null,
    }
  },
  mounted() {
    window.addEventListener('scroll', () => this.setActiveSection())
  },
  methods: {
    setActiveSection() {
      const sectionElements = document.querySelectorAll('[data-scrollspy]')
      for (const sectionElement of sectionElements) {
        const scrollPosition = window.scrollY
        const top = sectionElement.offsetTop
        const bottom = top + sectionElement.clientHeight
        const windowHeight = window.innerHeight
        const margin = windowHeight * 0.4
        if (scrollPosition > top - margin && scrollPosition < bottom + margin) {
          this.activeSectionId = sectionElement.dataset.scrollspy
        }
      }
    },
    scrollToSection(sectionId) {
      const selector = `[data-scrollspy="${sectionId}"]`
      this.$goTo(selector)
    },
  },
}
</script>

<style lang="scss" scoped>
$link: var(--v-secondary-base);

a {
  color: white !important;
  display: block;
  padding: 8px 24px;
  border-left: 4px solid transparent;
  transition: all 0.3s;
}

a:not(.active):hover {
  color: $link !important;
  transition: all 0.3s;
}

.active {
  color: $link !important;
  font-weight: 700;
  border-left: 4px solid $link;
  transition: all 0.3s;
}
</style>
