<template>
  <v-container>
    <SectionTitle>Skills</SectionTitle>
    <SkillCard v-for="skill in skills" :key="skill.tech" v-bind="skill" class="mt-12" />
  </v-container>
</template>

<script>
import SectionTitle from '@/components/SectionTitle'
import SkillCard from '@/components/SkillCard'

export default {
  components: {
    SectionTitle,
    SkillCard,
  },
  data() {
    return {
      skills: [
        {
          tech: 'Vue',
          logo: require('@/assets/logo-vue.svg'),
          addonTech: 'Vuetify',
          addonLogo: require('@/assets/logo-vuetify.svg'),
          stream: 'Frontend Development',
          rank: 'Expert',
          level: 100,
        },
        {
          tech: 'Django',
          logo: require('@/assets/logo-django.svg'),
          addonTech: 'Django Rest Framework',
          stream: 'Backend Development',
          rank: 'Expert',
          level: 100,
        },
        {
          tech: 'GitHub',
          logo: require('@/assets/logo-github.png'),
          addonTech: 'Apache',
          addonLogo: require('@/assets/logo-apache.png'),
          stream: 'CI/CD',
          rank: 'Advanced',
          level: 80,
        },
        {
          tech: 'React',
          logo: require('@/assets/logo-react.png'),
          addonTech: 'Ant Design',
          addonLogo: require('@/assets/logo-antd.png'),
          stream: 'Frontend Development',
          rank: 'Intermediate',
          level: 50,
        },
        {
          tech: 'Amazon Web Services',
          logo: require('@/assets/logo-aws.png'),
          stream: 'Dev Ops',
          rank: 'Novice',
          level: 25,
        },
      ],
    }
  },
}
</script>
